@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #151719; /* Dark Gray */
  --secondary-color: #383E42; /* Medium Gray */
  --accent-color: #D9A34D; /* Gold */
  --background-color: #fdf7f7; /* Deep Black */
  --text-color: #FFFFFF; /* White */
  --highlight-color: #6B625B; /* Warm Brown */
  --cta-color: #4CAF50; /* Green for CTA buttons */
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: 'Roboto', sans-serif;
}

h1, h2 {
  color: var(--accent-color);
  font-family: 'Comfortaa', sans-serif;
  font-weight: bold;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 1s ease-in-out;
}


/* a {
  color: var(--cta-color);
} */

/* button, .cta-button {
  background-color: var(--cta-color);
  color: var(--text-color);
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

button:hover, .cta-button:hover {
  background-color: var(--highlight-color);
} */

